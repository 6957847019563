<template>
  <div class="container">
    <div style="position: fixed;top: 0;left: 0;width: 100%">
      <van-notice-bar
          left-icon="volume-o"
          text="温馨提醒：工龄礼只能选择提交一次，请谨慎选择。提交后仍需要调整，请联系：员工服务部-牟妍老师进行修改"
      />
    </div>
    <div class="cont">
      <div class="title">可选礼物</div>
      <div class="list">
        <div :class="item.active?'act item':'item'" v-for="(item,index) in list" :key="item.id" @click="select(index)">
          <div class="img_box">
            <img class="img" :src="item.image">
          </div>
          <div class="info">
            <div class="title">{{ item.title }}</div>
            <div class="integral">积分：{{ item.grade }}分</div>
          </div>
        </div>
      </div>
      <div style="margin-top: 6px">
        <div class="title">已选礼物</div>
        <div class="slist">
          <div class="box" v-if="selected.length == 0">
            <van-empty image="search" description="您还没选择礼物呢！"/>
          </div>
          <div class="lists" v-for="(item,index) in selected" :key="item.id">
            <van-swipe-cell :stop-propagation="true">
              <div class="item">
                <div class="img_box">
                  <img class="img" :src="item.image">
                </div>
                <div class="info">
                  <div class="title">
                    <div class="title_tip">{{ item.title }}</div>
                    <div class="title_num">
                      <van-stepper v-model="item.value" :disabled="isSave" theme="round"
                                   :disable-plus="selectList[index].dis" button-size="22" disable-input/>
                    </div>
                  </div>
                  <div class="integral">
                    积分：{{ item.grade }}分
                  </div>
                </div>
              </div>
              <template #right>
                <van-button square text="删除" :disabled="isSave" @click="del(index)" type="danger"
                            class="delete-button"/>
              </template>
            </van-swipe-cell>
          </div>
        </div>
      </div>
    </div>
    <div class="active">
      <div class="tip">
        用户：{{ users.name }}；当前积分：{{ allgrade }}分；剩余积分：{{ grade }}分
      </div>
      <div class="btn">
        <van-button plain round size="small" :disabled="isSave" @click="rest">重新选择</van-button>
        <van-button plain type="primary" round size="small" :disabled="isSave" @click="save">确定选择</van-button>
      </div>
    </div>

  </div>
</template>

<script>
// import axios from 'axios'
import Vue from 'vue'
import vueEsign from 'vue-esign'
import api from "@/fetch/api";
import axios from "axios";
import { Dialog } from 'vant';

Vue.use(vueEsign)
export default {
  data() {
    return {
      grade: 0,
      allgrade: 0,
      //已选择礼物
      selected: [],
      // 实际礼物
      selectList: [],
      // 带】可选择
      list: [],
      users: {},
      isSave: false
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  watch: {
    selected: { // 深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        console.log(oldVal)
        var lists = []
        var grade = 0
        for (let i in val) {
          lists[i] = {}
          const num = val[i].value == null ? 1 : val[i].value
          lists[i].id = val[i].id
          lists[i].num = parseFloat(num)
          lists[i].grade = parseFloat(val[i].grade)
          grade += parseFloat(val[i].grade) * parseInt(num)
        }
        this.grade = this.allgrade - grade
        for (let j in lists) {
          const grade = lists[j].grade
          if (grade > this.grade) {
            lists[j].dis = true
          } else {
            lists[j].dis = false
          }
        }
        this.selectList = lists
      },
      deep: true // true 深度监听
    }
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem('userInfo'))
    if (user) {
      this.users = user
      // this.grade = 30
      // this.allgrade = 30
      this.init()
    } else {
      this.$router.replace({ name: 'Auto' })
    }

  },
  methods: {
    init() {
      const form = {
        userid: this.users.userid
      }
      api.getList(form)
          .then((res) => {
            if (res.code === 200) {
              const data = res.result
              this.isSave = data.is_preservation
              this.list = data.list
              const selecred = data.record
              var grades = 0
              for (let i in selecred) {
                const num = selecred[i].value == null ? 1 : selecred[i].value
                grades += parseFloat(selecred[i].grade) * parseInt(num)
              }
              this.selected = data.record
              const grade = data.user.working_value + grades
              this.allgrade = grade
              this.grade = grade
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    select(index) {
      const list = [...this.list]
      const grade = this.grade
      const grades = list[index].grade
      const active = list[index].active
      if (active) {
        this.grade = grade + grades
        list[index].active = false
        const indexs = this.searchArr(this.selected, list[index])
        this.selected.splice(indexs, 1)
      } else {
        if (grade - grades >= 0) {
          this.grade = grade - grades
          list[index].active = true
          this.selected.push(list[index])
        } else {
          this.$toast('您的积分不够啦！')
        }
      }
      this.list = list
    },
    // 更改数量是
    // change(val,index){
    //   console.log(val)
    //   console.log(index)
    // const num = parseInt(val)
    // const grades = parseInt(this.selected[index].grade)
    // const grade = this.grade
    // console.log('grades:'+grades)
    // console.log('grade:'+grade)
    // if(grade < grades){
    //   this.$toast('您的积分不够啦！')
    //   return
    // }
    // this.selected[index].num = num
    // },
    searchArr(list, item) {
      for (let i in list) {
        const id = list[i].id
        const ids = item.id
        if (id === ids) {
          return i
        }
      }
      return -1
    },
    del(index) {
      const list = [...this.list]
      const selected = [...this.selected]
      const indexs = this.searchArr(list, selected[index])
      this.list[indexs].active = false
      this.grade += selected[index].grade
      this.selected.splice(index, 1)
    },
    // 取消选择
    clear() {
      // const list = [...this.list]
      // const selected = [...this.selected]
      // const indexs = this.searchArr(list,selected[index])
      // this.list[indexs].active = false
      // this.grade += selected[index].grade
      // this.selected.splice(index,1)
    },
    // 积分剩余提醒
    save() {
      const that = this
      const list = this.selectList
      if (list.length == 0) {
        return
      }
      if (this.grade > 0) {
        Dialog.confirm({
          title: '积分剩余提醒',
          message: '您当前还剩余'+this.grade+'积分。礼品将季度月会上进行发送。',
        }).then(() => {
          that.confirmGift()
        }).catch(() => {
          // on cancel
        });
        return
      }
      that.confirmGift()
    },
    // 确定礼物提醒
    confirmGift() {
      const that = this
      const gift_name = this.selected.map(v => v.title)
      Dialog.confirm({
        title: '确定礼物提醒',
        message: '您确定选择礼物【'+ gift_name.join(',') +'】吗？礼品将季度月会上进行发送。',
      }).then(() => {
        that.confirmSave()
      }).catch(() => {
        // on cancel
      });
    },
    // 确定保存提交
    confirmSave() {
      axios.post('https://resapi.121314.com/sidebar/gift/submit', {
        userid: this.users.userid,
        gift_ids: this.selectList
      })
          .then((res) => {
            const result = res.data
            if (result.code === 200) {
              this.$toast('保存成功');
              setTimeout(function () {
                window.location.reload()
              }, 1500)
            } else {
              this.$toast('您当前积分不足以兑换所选商品');
            }
          })
          .catch(function (error) {
            console.log(error);
            this.$toast('保存失败');
          });
    },
    // 重新选择
    rest() {
      this.selected = []
      const list = [...this.list]
      for (let i in list) {
        list[i].active = false
      }
      this.list = list
      this.grade = this.allgrade
    }
  }
}
</script>

<style>
.container {

}

.cont {
  margin-top: 30px;
  padding: 20px 20px 120px 20px;
  font-size: 13px;
}

.title {
  padding: 10px;
  border-radius: 6px;
  background-color: #f9f9f9;
}

.list {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.slist {
  width: 100%;
  min-height: 130px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.box {
  width: 100%;
}

.item {
  width: 100%;
  max-width: 400px;
  margin: 10px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f9f9f9;
  border: 2px solid #f9f9f9;
}

.item.act {
  border: 2px solid #ee0a24;
}

.lists {
  width: 100%;
  max-width: 400px;
}

.lists .item {
  width: auto;
}

.item .img_box {
  width: 100px;
  border-radius: 10px;
  overflow: hidden;
}

.item .img_box .img {
  width: 100px;
  height: 100px;
}

.item .info {
  width: -moz-calc(100% - 130px);
  width: -webkit-calc(100% - 130px);
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item .info .title {
  font-size: 14px;
  width: 100%;
  height: 30px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title_tip {
  width: 100%;
  display: flex;
  align-items: center;
}

.item .info .title .title_num {
  width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item .info .title .title_num span {
  color: #666;
  font-size: 12px;
}

.integral {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  text-align: right;
}

.active {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 74px;
  background-color: #f9f9f9;
  border-top: 1px solid #eee;
}

.active .tip {
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
  background-color: #eee;
}

.btn {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn button {
  margin: 0 20px;
  font-size: 13px;
  padding: 5px 12px;
  border-radius: 4px;
}

.rest {
  color: #666;
  border: 1px solid #ddd;
  background: none;
}

.save {
  color: #1989fa;
  border: 1px solid #1989fa;
  background: none;
}

.goods-card {
  margin: 0;
  background-color: white;
}

.delete-button {
  height: 100%;
}

.van-stepper__input {
  width: 20px;
}
</style>
